import React from 'react';
import {Container} from "react-bootstrap";

export function Landing() {

    function scrollDown() {
        window.scrollTo(0, window.innerHeight);
    }

    return(
        <Container className={'d-flex justify-content-center flex-column align-items-center'} style={{maxWidth: '40rem' ,minHeight: '100vh'}}>
            <h1 className={'pb-4'}>BangBiangYang Coffee's</h1>
            <p1 className={'text-center'}>
                Here at BangBiangYang Coffee's we pride ourselves on the quality, care, and taste people see in our products.
                "The hot coffee we provide is like no other." Our moto compliments our franchise perfectly

                {/*
                        Compared to other companies our staff handle the beans from Deprexxizawa with the uttermost care,
                        making sure your cup of joe is up to high standard that we offer with all of our products. BangBiangYang Coffee's are a delicacy to behold.
                    */}
            </p1>

            <img role="button" className={'position-absolute'} style={{bottom: '20px'}} width={'60vw'} src={'/down-arrow-svgrepo-com.svg'} alt={'DownArrow'} onClick={scrollDown}/>
            <img role="button" className={'position-absolute bottom-0'} width={'60vw'} src={'/down-arrow-svgrepo-com.svg'} alt={'DownArrow'} onClick={scrollDown}/>
        </Container>

    )
}