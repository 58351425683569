import React from 'react'
import {NavBar} from "../molecule/header";

export function Layout({ children }) {
    return(
        <>
            <NavBar/>
            {children}
        </>
    )
}