import React from 'react';
import './App.css';
import {CoffeeList} from "./components/molecule/coffeeList";
import {Layout} from "./components/organisim/layout";
import {LocationList} from "./components/molecule/locationList";
import {Landing} from "./components/atom/landing";

function App() {

    return (
        <Layout>
            <Landing/>
            <span id={'anchor-coffee'}><CoffeeList amount={4}/></span>
            <span id={'anchor-location'}><LocationList amount={4}/></span>
        </Layout>
    );


}

export default App;
