import React from "react";
import { Card, Badge } from 'react-bootstrap';

export function LocationCard({ data }) {
    return(
        <Card className="h-100 shadow-sm bg-white rounded">
            <Card.Img variant={"top"} style={{aspectRatio: "1", objectFit: 'cover', maxHeight: '200px '}} src={data.image}/>
            <Card.Body className={"d-flex flex-column"}>
                <div className={"d-flex mb-2 justify-content-between"}>
                    <Card.Title className={"mb-0 font-weight-bold"}>
                        {data.name}
                    </Card.Title>
                    <Badge pill className={"mb-1"} variant={"warning"}>
                        {data.joints} locations
                    </Badge>
                </div>
                <Card.Text className={"text-secondary"}>{data.desc}</Card.Text>
            </Card.Body>
        </Card>
    )
}