import React from "react";
import { Toast } from 'react-bootstrap';

export function Confirmation({ toggle }) {
    return(
        <Toast onClose={() => toggle(false)}>
            <Toast.Header>
                <strong className="me-auto">Added item to Basket</strong>
                <small>just now</small>
            </Toast.Header>
            <Toast.Body>
                You have added some coffee to your cart.
            </Toast.Body>
        </Toast>
    )
}