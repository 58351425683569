import React from 'react'

export function NavBar() {

    return(
        <nav className={'navbar fixed-top navbar-expand-xl navbar-light bg-light d-flex justify-content-around'}>
            <a role="button" className={'nav-item text-decoration-none'} href={'#anchor-coffee'}>Coffee</a>
            <a role="button" className={'nav-item text-decoration-none'} href={'#anchor-location'}>Location</a>
            <a role="button" className={'nav-item text-decoration-none'}>LogIn</a>
        </nav>
    )
}