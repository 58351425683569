import React, {useState} from 'react'
import {Container, Row, Col, Button} from "react-bootstrap";
import {LocationCard} from "../atom/LocationCard";
import locations from '../../data/location.json';

export function LocationList({ amount }) {
    const [locationExpanded , setLocationExpanded] = useState(true);
    const [locationDisplaySize, setLocationDisplaySize] = useState(amount);
    const [locationButton, setLocationButton] = useState('Show More');

    function expand() {
        if (locationExpanded) {
            setLocationExpanded(false);
            setLocationDisplaySize(999);
            setLocationButton('Show Less');
            console.log(locationExpanded);
        }
        else {
            setLocationExpanded(true);
            setLocationDisplaySize(amount);
            setLocationButton('Show More');
            console.log(locationExpanded);
        }
    }

    return(
        <Container style={{minHeight: '100vh'}}>
            <h1 className={"p-5 text-center"}>Locations</h1>
            <Row className={'mb-sm-5'}>
                {locations.slice(0, locationDisplaySize).map(data => (
                    <Col className={"mb-5"} key={`${data.id}`}>
                        <LocationCard data={data}/>
                    </Col>
                ))}
            </Row>
            <div className={"text-center"}>
                <Button onClick={() => expand()} className={"text-center"} variant={"success"}>
                    { locationButton }
                </Button>
            </div>
        </Container>
    )
}