import React, {useState} from 'react'
import {Container, Row, Col, Button} from "react-bootstrap";
import { CoffeeCard } from '../atom/CoffeeCard';
import { Confirmation } from '../atom/Confirmation';
import coffee from '../../data/coffee.json';

export function CoffeeList({ amount }) {
    const [ordered, setOrdered] = useState(false);
    const [coffeeExpanded , setCoffeeExpanded] = useState(true);
    const [coffeeDisplaySize, setCoffeeDisplaySize] = useState(amount);
    const [coffeeButton, setCoffeeButton] = useState('Show More');

    function expand() {
        if (coffeeExpanded) {
            setCoffeeExpanded(false);
            setCoffeeDisplaySize(999);
            setCoffeeButton('Show Less');
            console.log(coffeeExpanded);
        }
        else {
            setCoffeeExpanded(true);
            setCoffeeDisplaySize(amount);
            setCoffeeButton('Show More');
            console.log(coffeeExpanded);
        }
    }
    function order(id) {
        setOrdered(true);
        console.log(id);
        setTimeout(() => {
            setOrdered(false);
        }, 3000)
    }

    return(
        <Container style={{minHeight: '100vh'}}>
            <h1 className={"p-5 text-center"}>Coffee Types</h1>
            {ordered && <Confirmation toggle={setOrdered}/> }
            <Row className={'mb-sm-5'}>
                {coffee.slice(0, coffeeDisplaySize).map(data => (
                    <Col className={"mb-5"} key={`${data.id}`}>
                        <CoffeeCard data={data} setOrdered={order}/>
                    </Col>
                ))}
            </Row>
            <div className={"text-center"}>
                <Button onClick={() => expand()} className={"text-center"} variant={"success"}>
                    { coffeeButton }
                </Button>
            </div>
        </Container>
    )
}